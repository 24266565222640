@font-face {
    font-family: Veneer;
    src: url('../assets/fonts/VeneerClean-Soft.ttf');
}

@font-face {
    font-family: Gotham;
    src: url('../assets/fonts/GothamCond-Book.otf');
}


.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#map-wrapper {
    min-height: 100%;
    width: 100%;
}

#map {
    height: 100vh;
    /* min-height: -webkit-fill-available; */
    width: 100%;
}

@media only screen and (max-width: 768px) {
    #map {
        /* min-height: calc(100vh - 170px); */
        /* top: 80px; */
        /* width: 100vw; */
        /* height: calc(100% - 80px); */
    }
}

html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}

.hidden {
    display: none;
}


html {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
    display: none;
}

/* header */

.panel__header {
    height: 70px;
    width: 360px;
    cursor: pointer;
    position: fixed;
    top: 120px;
    background-color: #414143;
}

.panel__header:hover .title {
    color: #00FF00;
}

.panel__headerTitle {
    display: block;
    margin: 0;
    padding: 0;
    float: left;
}

.panel__headerBack {
    background-image: url('../assets/arrow.svg');
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
    margin: 3px 0 0 0;
    padding: 0;
    float: right;
}

.panel__header:hover .panel__headerBack {
    background-image: url('../assets/arrowon.svg');
}

.nav__logo {
    cursor: pointer;
}

#nav-hamburger:hover {
    color: #00FF00;
}

/* panel main menu */

#panelLegal {
    margin: 190px 0 0 0;
    padding: 0 30px 0 30px;
}

#panelAbout {
    margin: 190px 0 0 0;
    padding: 0 50px 0 70px;
}

#panelSearch {
    margin: 190px 0 0 0;
    padding: 0 30px 0 30px;
}

#panelInfo {
    margin: 190px 0 0 0;
    padding: 0 30px 0 60px;
}

#panelUsers {
    margin: 190px 0 0 0;
    padding: 0 65px 0 65px;
}

#panelFilter {
    margin: 230px 0 0 0;
    padding: 0 65px 0 65px;
}

#mainMenu {
    margin-top: 170px;
    margin-left: 15%;
}

#mainMenu .menuItem {
    margin-top: 15%;
}

#mainMenu .menuItem:first-child {
    margin-top: 0;
}

#mainMenu .menuItem p, #panelAbout a {
    -webkit-transition: color 0.5s ease-out;
    -moz-transition: color 0.5s ease-out;
    -o-transition: color 0.5s ease-out;
    transition: color 0.5s ease-out;
}

#mainMenu .menuItem:hover p, #panelAbout a:hover {
    color: #00FF00;
    cursor: pointer;
}

.title {
    font-family: Veneer;
    font-size: 29px;
    letter-spacing: 0.2rem;
    color: white;
    margin: 0;
    padding: 0;
}

#mainMenu .menuDesc {
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    color: #b3b3b3;
    margin: 0;
    padding: 0;
    white-space: pre-line;
    line-height: 1.5em;
}

/* panel about */

#panelAbout a {
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    color: white;
    display: block;
    text-decoration: none;
    margin: 1.3em 0;
}

#panelAbout a.mail {
    margin: 2px 0 25px 0;
}

#panelAbout h2 {
    font-family: Veneer;
    font-size: 25px;
    font-weight: normal;
    letter-spacing: 0.2rem;
    color: white;
}

#panelAbout h3 {
    font-family: Veneer;
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 0.2rem;
    color: white;
    margin: 0 0 0 0;
}

#panelLegal h3 {
    font-family: Veneer;
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 0.2rem;
    color: white;
    margin: 15px 0 10px 0;
}

#panelAbout h4 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.2rem;
    color: white;
    margin: 0 0 5px 0;
}

#panelAbout p, #panelLegal p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    color: white;
    line-height: 1.5;
    margin-bottom: 0;
}

#panelAbout img {
    width: 60%;
}

#panelAbout ul, #panelLegal ul {
    list-style: none;
    margin: 0 0 30px 0;
    padding: 0;
}

#panelAbout ul li, #panelLegal ul li {
    margin: 0;
    padding: 10px 0 0 35px;
    background-image: url('../assets/facebook.svg');
    background-repeat: no-repeat;
}

#panelAbout ul li a {
    display: inline;
}

#legal li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    color: white;
}

#legal p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    color: white;
}

#legal {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

#legal ul {
    list-style: disc;
    margin: 0 0 30px 0;
    padding: 0;
}

#legal ul li {
    margin: 0;
    padding: 10px 0 0 5px;
    background-image: none;
}

#panelUsers p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: larger;
    color: white;
    line-height: 1.5;
}

#panelUsers a {
    display: block;
    margin: 20px 0 0 0;
    width: 200px;
}

/* panelSearch */

#panelSearch p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    color: #b3b3b3;
}


/* panel filter */

#panelFilter ul {
    margin: 0;
}

#panelFilter ul {
    list-style: none;
    padding: 0;
}

#panelFilter ul li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: larger;
    color: white;
    margin: 0 0 15% 0;
    padding: 2px 0 0 45px;
    background-repeat: no-repeat;
}

#panelFilter ul li.active {
    color: #00FF00;
    cursor: pointer;
}

#panelFilter ul li.noActive {
    color: white;
    cursor: pointer;
}

#panelFilter ul li:nth-child(1) {
    background-image: url('../assets/sklo.svg');
    background-position: 4px;
}

#panelFilter ul li:nth-child(2) {
    background-image: url('../assets/plasty.svg');
    background-position: 4px;
}

#panelFilter ul li:nth-child(3) {
    background-image: url('../assets/papir.svg');
    background-position: 4px;
}

#panelFilter ul li:nth-child(4) {
    background-image: url('../assets/napojovekartony.svg');
    background-position: 4px;
}

#panelFilter ul li:nth-child(5) {
    background-image: url('../assets/kovy.svg');
    background-position: 4px;
}

#panelFilter ul li:nth-child(6) {
    background-image: url('../assets/sbernydvur.svg');
}

table {
    width: 100%;
    display: none;
}

td {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: white;
    cursor: pointer;
}

td.half {
    width: 50%;
}

td.image {
    width: 40px;
    text-align: center;
}

td.active {
    color: #00FF00;
    cursor: pointer;
}

td.noActive {
    color: white;
    cursor: pointer;
}

td img {
    height: 23px;
}

/* info window */

#infoWindow {
    width: 180px;
    margin: 0 0 0 10px;
}

#infoWindowContainer {
    margin: 0 0 25px 0;
    padding: 0;
}

#infoWindow h2 {
    font-family: Veneer;
    font-size: 29px;
    letter-spacing: 0.2rem;
    font-weight: normal;
    color: #141213;
    margin: 0 0 10px 0;
    padding: 0;
}

#infoWindowContainer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

#infoWindowContainer ul li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    color: black;
    margin: 15px 0 0 0;
    padding: 6px 0 0 35px;
    background-repeat: no-repeat;
}

#iwPaper {
    background-image: url('../assets/papir.svg');
}

#iwPlastic {
    background-image: url('../assets/plasty.svg');
}

#iwGlass {
    background-image: url('../assets/sklo.svg');
}

#iwDrinkCarton {
    background-image: url('../assets/napojovekartony.svg');
}

#iwMetal {
    background-image: url('../assets/kovy.svg');
}

#iwPlace {
    background-image: url('../assets/sbernydvurDetail.svg');
}

#infoWindow img {
    width: 45px;
    cursor: pointer;
}

#infoWindow h3 {
    color: #458CEB;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
}

#infoWindow p {
    color: #458CEB;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.1em;
    font-weight: normal;
    margin-bottom: 10px;
    line-height: 1.5em;
}

#infoWindow hr {
    border-top: 1px solid #458CEB;
}

/* Panel info */

#panelInfo ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

#panelInfo ul li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    color: white;
    margin: 0 0 5px 0;
    padding: 0;
    text-align: center;
}

#panelInfo li a {
    cursor: pointer;
    text-decoration: none;
    color: white;
}

#panelInfo li a:hover, .letterActive {
    color: #00FF00;
}

#panelInfo section {
    margin: 0 30px 30px 0;
    /* overflow-y: scroll; */
    height: 100%;
    display: block;
}

#panelInfo section div {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.0em;
}

#panelInfo section div.letter {
    margin-top: 10px;
    font-size: 1.6em;
}

#alphabet {
    position: fixed;
    top: 140px;
    left: 10px;
}

section span {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    color: white;
    font-weight: bold;
}

.infoItem a {
    color: Aqua;
    text-decoration: none;
}

.hr {
    margin-top: 2px;
    margin-bottom: 10px;
    background-color: white;
    height: 1px;
}

.infoItem {
    margin: 0 0 10px 0;
    line-height: 1.4em;
}

.anchor {
    padding-top: 190px;
    margin-top: -190px;
}

@media screen and (max-width: 768px) {

    .anchor {
        padding-top: 150px;
        margin-top: -150px;
    }

    .title {
        text-align: center;
    }

    table {
        display: table;
    }

    #mainMenu {
        margin-top: 80px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        padding-top: 40px;
    }

    #mainMenu .menuItem {
        margin-top: 10%;
    }

    #mainMenu .menuDesc {
        text-align: center;
    }

    #panelInfo section {
        margin: 0 20px 30px 20px;
        /* overflow-y: scroll; */
        height: 100%;
        display: block;
    }

    #panelInfo ul li {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 0.8em;
        color: white;
        margin: 0 0 2px 0;
        padding: 0;
        text-align: center;
    }

    #alphabetMobile {
        position: absolute;
        display: block;
        bottom: 20px;
        right: 5px;
    }

    .panel__header {
        top: 80px;
        width: 100%;
    }

    #panelInfo {
        margin: 150px 0 0 0;
        padding: 0 30px 0 40px;
    }

    #panelLegal {
        margin: 150px 0 0 0;
        padding: 0 30px 0 30px;
    }

    #panelAbout {
        margin: 150px 0 0 0;
        padding: 0 50px 0 70px;
    }

    #panelSearch {
        margin: 150px 0 0 0;
        padding: 0 30px 0 30px;
    }

    #panelUsers {
        margin: 150px 0 0 0;
        padding: 0 65px 0 65px;
    }

    #panelFilter {
        margin: 150px 0 0 0;
        padding: 0 5px 20px 5px;
    }

    .panelWrapper {
        background-color: #414143;
    }
}

table {
    width: 100%;
    height: 150px;
}

td {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: white;
}

td.half {
    width: 50%;
}

td.image {
    width: 40px;
    text-align: center;
}

td.active {
    color: #00FF00;
}

td.noActive {
    color: white;
}

td img {
    height: 23px;
}

/* Admin */
.filterAdmin {
    margin: 20px 0 20px 0 !important;
}

#detailMarker p {
    font-family: Gotham, serif;
    font-size: 22px;
    color: white;
    margin: 0;
    padding: 0;
    float: left;
    display: block;
}

#detailMarker img {
    float: right;
    height: 30px;
}

#markers {
    margin: 120px 0 0 0;
    padding: 20px;
}

#markers p {
    font-family: Gotham, serif;
    font-size: 22px;
    color: white;
    margin: 0;
    padding: 0;
    float: left;
    display: block;
}

.clearfix {
    clear: both;
}

.menuItem {
    margin: 0px 0px 25px 0;
    cursor: pointer;
    width: 100%;
}

.menuItem img {
    float: right;
    height: 30px;
}

#markers .menuItem:hover p {
    color: #00FF00;
    cursor: pointer;
}

#markers .menuItem p {
    -webkit-transition: color 0.3s ease-out;
    -moz-transition: color 0.3s ease-out;
    -o-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
}

#pinLocation {
    left: calc(50% + 180px);
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    z-index: 999;
}

#address {
    background-color: white;
    padding: 10px;
    border: 1px solid lightgray;
    left: calc(50% + 180px);
    transform: translate(-50%, -50%);
    position: absolute;
    top: 60%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    text-align: center;
    user-select: none;
    pointer-events: none;
}

#pinLocation img {
    height: 100px;
    -webkit-transform: translate(0, -50%)
}

#searchWrapper {
    position: absolute;
    width: 350px;
    height: 10px;
    top: 7px;
    right: 7px;
    z-index: 999;
    background: transparent;
}

@media screen and (max-width: 768px) {
    #pinLocation {
        left: 50%;
        top: calc(50% + 40px);
    }

    #markers {
        margin: 80px 0 0 0;
    }
}

#direction {
    padding: 10px 10px 10px 1px;
}

#newMarkerButton {
    top: 90px;
    z-index: 999;
    left: 370px;
    position: absolute;
}

#loginInfo {
    position: absolute;
    padding: 5px;
    top: 45px;
    left: 370px;
    z-index: 999;
    background-color: white;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    font-size: 1em;
    -webkit-box-shadow: 6px 7px 5px -4px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 6px 7px 5px -4px rgba(0, 0, 0, 0.3);
    box-shadow: 6px 7px 5px -4px rgba(0, 0, 0, 0.3);
}

#user {
    height: 25px;
    float: left;
    padding-right: 10px;
}

#userInfo {
    height: 25px;
    float: left;
}

#userInfo p {
    display: block;
    float: right;
}

#loginLogo {
    background-color: #141213;
    height: 100px;
}

#loginLogo img {
    padding: 15px 0 15px 0;
    height: 70px;
}

#map * { outline: none !important; }
