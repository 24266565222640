/* Cookies */

button:focus {outline:0;}

.cookies-consent {
    position: fixed;
    left: calc(5vw + 360px);
    bottom: -100%;
    width: calc(90vw - 360px);
    min-height: 200px;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 20200;
    transition: bottom 500ms ease-in-out, opacity 300ms ease-in-out;
}

@media only screen and (max-width: 1280px) {
    .cookies-consent {
        width: 100%;
        max-height: 400px;
        left: 0;
        overflow-y: scroll;
    }
}

.cookies-consent--active {
    bottom: 0;
}

.cookies-consent__body {
    display: flex;
    flex-flow: row wrap;
    padding: 2em;
}

.cookies-consent__col-100 {
    width: 100%;
}

.cookies-consent__col-80 {
    width: 80%;
}

.cookies-consent__col-20 {
    width: 20%;
}

.cookies-consent__col-66 {
    width: 66%;
}

.cookies-consent__col-50 {
    width: 50%;
}

.cookies-consent__col-33 {
    width: 33%;
}

.cookies-consent__col-25 {
    width: 25%;
}

@media only screen and (max-width: 768px) {
    .cookies-consent__col-100, .cookies-consent__col-80, .cookies-consent__col-66, .cookies-consent__col-50, .cookies-consent__col-33, .cookies-consent__col-20, .cookies-consent__col-25 {
        width: 100%;
    }

    .cookies-consent {
        z-index: 1;
    }
}

.cookies-consent .cookies-consent__col-split p {
    column-count: 2;
    column-gap: 20px;
}

.cookies-consent__title {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.cookies-consent__text p {
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
    padding: 0 20px 20px 0;
}

.cookies-consent__config {
    padding-top: 20px;
}

.cookies-consent__config label {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
}

.cookies-consent__config input {
    display: inline-block;
    position: relative;
    top: 2px;
    border: 2px solid #6bb13a;
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    outline: none;
}

.cookies-consent__config input:checked {
    background-color: #409a00;
}

.cookies-consent__config p {
    color: #fff;
    line-height: 1.5;
    font-size: 14px;
    padding: 0 20px 20px 0;
}

.cookies-consent__bottom-line {
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
}

.cookies-consent__button-group {
    padding-top: 20px;
}

.cookies-consent__button {
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    background-color: #409a00;
    border: 1px solid #6bb13a;
    border-radius: 3px;
    cursor: pointer;
    padding: 6px 12px 4px 12px;
    margin-bottom: 40px;
}

.cc-helper-buttons {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 200;
    background-color: #fff;
}

#cc-general-button, #cc-settings-button {
    border: 1px solid black;
    padding: 4px;
    cursor: pointer;
}

/* Cookies content */

.cookies-consent__title {
    font-family: Veneer;
    font-size: 29px;
    font-weight: normal;
    letter-spacing: 0.2rem;
    color: white;
}

.cookies-consent a {
    color: #6bb13a;
    text-decoration: none;
}

.cookies-consent a:hover {
    text-decoration: underline;
}

.cookies-consent p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
} 

.center {
    text-align: center;
}

.cookies-consent button {
    width: 80%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin-bottom: 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: Veneer;
    font-weight: normal;
    letter-spacing: 0.1rem;
    font-size: 22px;
    padding: 0;
    border-radius: 6px;
    min-height: 40px;
}

.buttonCookiesFull {
    border: 2px solid #6BB03A;
    background-color: #32702F;
    color: white;
}

.buttonCookies {
    border: 2px solid #6BB03A;
    background-color: transparent;
    color: #6BB03A;
}